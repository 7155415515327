export default [

  {
    path: '/setting',
    name: 'setting',
    component: () => import('@/views/setting/Setting.vue'),
    meta: {
      resource: 'all',
      action: 'manage',
    },
  },
];
