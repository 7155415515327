export default [
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/miscellaneous/Error.vue'),
    meta: {
      layout: 'full',
      resource: 'Miscellaneous',
      action: 'read',
    },
  },
  {
    path: '/unauthorized',
    name: 'unauthorized',
    component: () => import('@/views/miscellaneous/Unauthorized.vue'),
    meta: {
      layout: 'full',
      resource: 'Miscellaneous',
      action: 'read',
    },
  },
  {
    path: '/under-maintenance',
    name: 'under-maintenance',
    component: () => import('@/views/miscellaneous/UnderMaintenance.vue'),
    meta: {
      layout: 'full',
      resource: 'Miscellaneous',
      action: 'read',
    },
  },
  {
    path: '/verification',
    name: 'verification',
    component: () => import('@/views/miscellaneous/VerificationToken.vue'),
    meta: {
      layout: 'full',
      resource: 'Miscellaneous',
      action: 'read',
    },
  },

];
