export default {
  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',

  // Endpoints
  loginEndpoint: '/v1/auth/login',
  registerEndpoint: '/v1/auth/register',
  refreshEndpoint: '/v1/user/refresh-token',
  logoutEndpoint: '/v1/user/logout',
  forgotPasswordEndpoint: '/v1/auth/forgot-password',
  resetPasswordEndpoint: '/v1/auth/reset-password',
  firebaseTokenEndpoint: '/v1/auth/firebase-token',

  // Permission
  permissionListEndPoint: '/v1/permission',
  permissionGroupListEndPoint: '/v1/permission/group',

  // Endpoint role
  roleListEndpoint: '/v1/role',
  roleDetailEndpoint: '/v1/role',
  roleAbilitiesEndpoint: '/v1/role/abilities',
  roleCreateEndpoint: '/v1/role',
  roleUpdateEndpoint: '/v1/role',
  roleDeleteEndpoint: '/v1/role',

  // Endpoint manager
  managerListEndpoint: '/v1/manager',
  managerDetailEndpoint: '/v1/manager',
  managerCreateEndpoint: '/v1/manager',
  managerUpdateEndpoint: '/v1/manager',
  managerDeleteEndpoint: '/v1/manager',

  // Endpoint user
  userProfileEndpoint: '/v1/user/me',
  userProfileUpdateEndpoint: '/v1/user/update',
  userChangePasswordEndpoint: '/v1/user/change-password',
  userVerifyEmailEndpoint: '/v1/user/verify-email',
  userRequestVerifyEmailEndpoint: '/v1/user/request-verify-email',
  userRequestMissCallOTPEndpoint: '/v1/user/request-miss-call-otp',
  userRequestVerifyPhoneOTPEndpoint: '/v1/user/request-verify-phone',
  userResetPasswordWithOTPEndpoint: '/v1/user/reset-password-with-otp',
  userVerifyPhoneEndpoint: '/v1/user/verify-phone',
  userACLEndpoint: '/v1/user/acl',

  // upload attachment
  attachmentUploadEndpoint: "/v1/attachment/upload",
  attachmentDeleteEndpoint: "/v1/attachment",

  // job
  jobEndPoint: "/v1/job",
  jobReRunEndPoint: "/v1/job/re-run",

  // system log
  systemLogEndPoint: "/v1/system-log",

  utilsProvinceEndpoint: '/v1/province',
  utilsDistrictEndpoint: '/v1/district',
  utilsWardEndpoint: '/v1/ward',

  // tingee
  tingeeVerifyOCBInfoEndpoint: '/v1/tingee/verify-ocb-info',
  tingeeVerifyOCBInfoWithOtpEndpoint: '/v1/tingee/verify-ocb-info-with-otp',
  tingeeOCBAccountEndpoint: '/v1/tingee/ocb-account',
  tingeeOCBCreateVAAccountEndpoint: '/v1/tingee/ocb/create-va-coporation',
  tingeeDeleteAccountEndpoint: '/v1/tingee',

  // Endpoints contact group
  createEndpoint: '/',
  updateEndpoint: '/',
  detailEndpoint: '/',
  listEndpoint: '',
  selectEndpoint: '/select',
  deleteEndpoint: '/',
  importEndpoint: '/import',
  downloadTemplateFileEndpoint: '/download-template',
  exportEndpoint: '/export',
  analyticsEndpoint: '/analytics',

};
