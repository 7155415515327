// eslint-disable-next-line import/no-cycle
import jwtDefaultConfig from './jwtDefaultConfig';

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null;

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig };

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false;

  // For Refreshing Token
  subscribers = [];

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns;
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig };

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // Get token from localStorage
        const accessToken = this.getToken();

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`;
        }
        return config;
      },
      error => Promise.reject(error),
    );

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => response.data,
      error => {
        // const { config, response: { status } } = error
        const { config, response } = error;
        const originalRequest = config;

        // if (status === 401) {
        if (response && response.status === 401) {
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true;
            this.refreshToken()
              .then(r => {
                this.isAlreadyFetchingAccessToken = false;

                // Update accessToken in localStorage
                this.setToken(r.data.access_token);
                this.setRefreshToken(r.data.refresh_token);

                this.onAccessTokenFetched(r.data.access_token);
                return true;
              })
              .catch(() => {
                // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
                localStorage.removeItem(this.jwtConfig.storageTokenKeyName);
                localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName);

                // Remove userData from localStorage
                localStorage.removeItem('userData');
                this.onAccessTokenFetched(null);
                window.location.href = '/login';
                return false;
              });
          }
          const retryOriginalRequest = new Promise(resolve => {
            this.addSubscriber(accessToken => {
              // Make sure to assign accessToken according to your response.
              // Check: https://pixinvent.ticksy.com/ticket/2413870
              // Change Authorization header
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`;
              resolve(this.axiosIns(originalRequest));
            });
          });
          return retryOriginalRequest;
        }
        return Promise.reject(error);
      },
    );
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken));
  }

  addSubscriber(callback) {
    this.subscribers.push(callback);
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName);
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName);
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value);
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value);
  }

  login(...args) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args);
  }

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args);
  }

  refreshToken() {
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
      refresh_token: this.getRefreshToken(),
    });
  }

  firebaseToken(firebaseToken) {
    const savedClientToken = localStorage.getItem('clientToken');
    if (savedClientToken !== firebaseToken) {
      localStorage.setItem('clientToken', firebaseToken);
      return this.axiosIns.post(this.jwtConfig.firebaseTokenEndpoint, {
        firebaseToken,
      });
    }
    return firebaseToken;
  }

  // role
  createRole(...args) {
    return this.axiosIns.post(this.jwtConfig.roleCreateEndpoint, ...args);
  }

  updateRole(data) {
    return this.axiosIns.put(`${this.jwtConfig.roleUpdateEndpoint}/${data.id}`, data);
  }

  getRoles(params) {
    return this.axiosIns.get(this.jwtConfig.roleListEndpoint, {
      params,
    });
  }

  getRoleDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.roleDetailEndpoint}/${id}`);
  }

  getRoleAbilities(id) {
    return this.axiosIns.get(`${this.jwtConfig.roleAbilitiesEndpoint}/${id}`);
  }

  deleteRoles(data) {
    return this.axiosIns.delete(this.jwtConfig.roleDeleteEndpoint, { data });
  }

  // manager
  createManager(...args) {
    return this.axiosIns.post(this.jwtConfig.managerCreateEndpoint, ...args);
  }

  updateManager(data) {
    return this.axiosIns.put(`${this.jwtConfig.managerUpdateEndpoint}/${data.id}`, data);
  }

  getManagers(params) {
    return this.axiosIns.get(this.jwtConfig.managerListEndpoint, {
      params,
    });
  }

  deleteManagers(data) {
    return this.axiosIns.delete(this.jwtConfig.managerDeleteEndpoint, { data });
  }

  getManagerDetail(params) {
    return this.axiosIns.get(this.jwtConfig.managerDetailEndpoint, {
      params,
    });
  }

  // Permission
  getPermissions() {
    return this.axiosIns.get(this.jwtConfig.permissionListEndPoint);
  }

  getPermissionGroups() {
    return this.axiosIns.get(this.jwtConfig.permissionGroupListEndPoint);
  }

  // user
  getUserProfile() {
    return this.axiosIns.get(this.jwtConfig.userProfileEndpoint);
  }

  updateUserProfile(...args) {
    return this.axiosIns.post(this.jwtConfig.userProfileUpdateEndpoint, ...args);
  }

  verifyEmail(...args) {
    return this.axiosIns.post(this.jwtConfig.userVerifyEmailEndpoint, ...args);
  }

  verifyPhone(...args) {
    return this.axiosIns.post(this.jwtConfig.userVerifyPhoneEndpoint, ...args);
  }

  getACL(...args) {
    return this.axiosIns.get(this.jwtConfig.userACLEndpoint, ...args);
  }

  requestVerifyEmail(...args) {
    return this.axiosIns.post(this.jwtConfig.userRequestVerifyEmailEndpoint, ...args);
  }

  requestMissCallOTP(...args) {
    return this.axiosIns.post(this.jwtConfig.userRequestMissCallOTPEndpoint, ...args);
  }

  requestVerifyPhoneOtp(...args) {
    return this.axiosIns.post(this.jwtConfig.userRequestVerifyPhoneOTPEndpoint, ...args);
  }

  forgotPassword(...args) {
    return this.axiosIns.post(this.jwtConfig.forgotPasswordEndpoint, ...args);
  }

  resetPasswordWithOTP(...args) {
    return this.axiosIns.post(this.jwtConfig.resetPasswordEndpoint, ...args);
  }

  changeUserPassword(...args) {
    return this.axiosIns.post(this.jwtConfig.userChangePasswordEndpoint, ...args);
  }

  // attachment
  uploadAttachment(data) {
    return this.axiosIns.post(this.jwtConfig.attachmentUploadEndpoint, data);
  }

  deleteAttachment(data) {
    return this.axiosIns.delete(this.jwtConfig.attachmentDeleteEndpoint, { data });
  }

  // Job
  fetchJobs(params) {
    return this.axiosIns.get(this.jwtConfig.jobEndPoint, { params });
  }

  getJobDetail(id) {
    return this.axiosIns.get(`${this.jwtConfig.jobEndPoint}/${id}`);
  }

  reRunJob(id) {
    return this.axiosIns.get(`${this.jwtConfig.jobReRunEndPoint}/${id}`);
  }

  getProvince() {
    return this.axiosIns.get(this.jwtConfig.utilsProvinceEndpoint);
  }

  getDistricts(provinceId) {
    return this.axiosIns.get(
      this.jwtConfig.utilsDistrictEndpoint, { params: { provinceId } },
    );
  }

  getWards(districtId) {
    return this.axiosIns.get(
      this.jwtConfig.utilsWardEndpoint, { params: { districtId } },
    );
  }

  // System log
  fetchSystemLogs(params) {
    return this.axiosIns.get(this.jwtConfig.systemLogEndPoint, { params });
  }

  // tingee
  tingeeVerifyOCBInfo(...args) {
    return this.axiosIns.post(this.jwtConfig.tingeeVerifyOCBInfoEndpoint, ...args);
  }

  tingeeVerifyOCBInfoWithOtp(...args) {
    return this.axiosIns.post(this.jwtConfig.tingeeVerifyOCBInfoWithOtpEndpoint, ...args);
  }

  tingeeOCBCreateVAAccount(...args) {
    return this.axiosIns.post(this.jwtConfig.tingeeOCBCreateVAAccountEndpoint, ...args);
  }

  tingeeGetOCBAccount() {
    return this.axiosIns.get(this.jwtConfig.tingeeOCBAccountEndpoint);
  }

  deleteTingees(data) {
    return this.axiosIns.delete(this.jwtConfig.tingeeDeleteAccountEndpoint, { data });
  }

  // Contact Group
  createItem(path, data) {
    return this.axiosIns.post(`/v1/${path}${this.jwtConfig.createEndpoint}`, data);
  }

  updateItem(path, { data, id }) {
    return this.axiosIns.put(`/v1/${path}${this.jwtConfig.updateEndpoint}${id}`, data);
  }

  downloadTemplateFile(path, data) {
    return this.axiosIns.get(`/v1/${path}${this.jwtConfig.downloadTemplateFileEndpoint}`, data);
  }

  importItem(path, data) {
    return this.axiosIns.post(`/v1/${path}${this.jwtConfig.importEndpoint}`, data);
  }

  exportItem(path, params) {
    return this.axiosIns.get(`/v1/${path}${this.jwtConfig.exportEndpoint}`, { params });
  }

  getItems(path, params) {
    return this.axiosIns.get(`/v1/${path}${this.jwtConfig.listEndpoint}`, {
      params,
    });
  }

  selectItem(path, params) {
    return this.axiosIns.get(`/v1/${path}${this.jwtConfig.selectEndpoint}`, {
      params,
    });
  }

  getItemDetail(path, id) {
    return this.axiosIns.get(`/v1/${path}${this.jwtConfig.detailEndpoint}${id}`);
  }

  getAnalytic(path, params) {
    return this.axiosIns.get(`/v1/${path}${this.jwtConfig.analyticsEndpoint}`, { params });
  }

  deleteItem(path, data) {
    return this.axiosIns.delete(`/v1/${path}${this.jwtConfig.deleteEndpoint}`, { data });
  }

  getTags() {
    return this.axiosIns.get(`/v1/template/tags`);
  }

  commonPost(path, data) {
    return this.axiosIns.post(`/v1/${path}`, data);
  }

  commonGet(path, params) {
    return this.axiosIns.get(`/v1/${path}`, {params});
  }
}
