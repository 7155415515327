import moment from 'moment';
import useJwt from './jwt/useJwt';

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  return (
    localStorage.getItem('userData')
    && localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
  );
};

export const getUserData = () => {
  const json = JSON.parse(localStorage.getItem('userData'));

  // set lai logo
  // $themeConfig.app.appLogoImage = json.configuration.brandLogo;
  return json;
};

export const getUserRole = () => {
  const userData = getUserData();
  if (userData && userData.role) {
    return userData.role;
  }
  return null;
};

export const getUserConfiguration = () => {
  const json = JSON.parse(localStorage.getItem('userConfiguration'));

  // set lai logo
  // $themeConfig.app.appLogoImage = json.configuration.brandLogo;
  return json;
};

export const isLimitTenantsInRoom = () => {
  const userConfiguration = getUserConfiguration();
  if (userConfiguration && userConfiguration.limitTenantsInRoom) {
    return userConfiguration.limitTenantsInRoom;
  }
  return false;
};

export const isAllowContractPaymentDayConfig = () => {
  const userConfiguration = getUserConfiguration();
  if (userConfiguration && userConfiguration.allowContractPaymentDayConfig) {
    return userConfiguration.allowContractPaymentDayConfig;
  }
  return false;
};

export const getServiceFeeCalculationType = () => {
  const userConfiguration = getUserConfiguration();
  if (userConfiguration && userConfiguration.serviceFeeCalculateType) {
    return userConfiguration.serviceFeeCalculateType;
  }
  return 'full';
};

export const isEnableLocationModule = () => {
  const userConfiguration = getUserConfiguration();
  if (userConfiguration && userConfiguration.enableLocationModule) {
    return userConfiguration.enableLocationModule;
  }
  return false;
};

export const isEnableContractAssetCheck = () => {
  const userConfiguration = getUserConfiguration();
  if (userConfiguration && userConfiguration.enableContractAssetCheck) {
    return userConfiguration.enableContractAssetCheck;
  }
  return false;
};

export const updateUserConfiguration = configuration => {
  localStorage.setItem('userConfiguration', JSON.stringify(configuration));
};

export const updateUserAbilities = abilities => {
  const json = JSON.parse(localStorage.getItem('userData'));
  json.ability = abilities;
  localStorage.setItem('userData', JSON.stringify(json));

  return json;
};

export const updateUserData = userData => {
  const json = JSON.parse(localStorage.getItem('userData'));
  const abilities = json.ability;
  localStorage.setItem('userData', JSON.stringify({ ...userData, ability: abilities }));

  return json;
};

export const isEnableBed = () => {
  const userConfiguration = getUserConfiguration();
  if (userConfiguration && userConfiguration.enableBed !== undefined && userConfiguration.enableBed !== null) {
    return userConfiguration.enableBed;
  }
  return true;
};

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = () => {
  const userData = getUserData();
  if (userData && userData.role) return userData.role.homeRoute;
  return { name: 'auth-login' };
};

export const parseDateToString = (date, format) => {
  if (moment(date, "YYYY-MM-DDTHH:mm:ss.SSSZ", true).isValid()) {
    const dateString = moment(date, "YYYY-MM-DDTHH:mm:ss.SSSZ").format(format || 'DD-MM-YYYY');
    return dateString;
  }
  if (moment(date, 'DD-MM-YYYY', true).isValid()) {
    const dateString = moment(date, 'DD-MM-YYYY').format('DD-MM-YYYY');
    return dateString;
  }
  if (moment(date, 'DD-MM-YYYY HH:mm', true).isValid()) {
    const dateString = moment(date, 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY HH:mm');
    return dateString;
  }
  return null;
};

export const parseDateToMonthString = date => {
  if (moment(date, "YYYY-MM-DDTHH:mm:ss.SSSZ", true).isValid()) {
    return moment(date, "YYYY-MM-DDTHH:mm:ss.SSSZ").format('MM-YYYY');
  }
  if (moment(date, 'MM-YYYY', true).isValid()) {
    return moment(date, 'MM-YYYY').format('MM-YYYY');
  }

  return null;
};
