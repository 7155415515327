export default [
  {
    path: '/contact-groups',
    name: 'contact-groups',
    component: () => import('@/views/apps/contact-group/list/ContactGroupList.vue'),
    meta: {
      resource: 'contact-group',
      action: 'read',
    },
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: () => import('@/views/apps/contact/list/ContactList.vue'),
    meta: {
      resource: 'contact',
      action: 'read',
    },
  },
  {
    path: '/zalo-accounts',
    name: 'zaloAccounts',
    component: () => import('@/views/apps/zalo-account/list/ZaloAccountList.vue'),
    meta: {
      resource: 'zaloAccount',
      action: 'read',
    },
  },
  {
    path: '/zalo/callback',
    name: 'zalo-callback',
    component: () => import('@/views/apps/zalo-account/callback/ZaloCallback.vue'),
    meta: {
      resource: 'zaloAccount',
      action: 'read',
      layout: 'full',
    },
  },
  {
    path: '/zalo-templates',
    name: 'zaloTemplates',
    component: () => import('@/views/apps/zalo-template/list/ZaloTemplateList.vue'),
    meta: {
      resource: 'zaloTemplate',
      action: 'read',
    },
  },
  {
    path: '/automation',
    name: 'automation',
    component: () => import('@/views/apps/automation/list/AutomationList.vue'),
    meta: {
      resource: 'automation',
      action: 'read',
    },
  },
  {
    path: '/campaign',
    name: 'campaign',
    component: () => import('@/views/apps/campaign/list/CampaignList.vue'),
    meta: {
      resource: 'campaign',
      action: 'read',
    },
  },
  {
    path: '/message',
    name: 'message',
    component: () => import('@/views/apps/message/list/MessageList.vue'),
    meta: {
      resource: 'message',
      action: 'read',
    },
  },
];
